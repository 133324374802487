import { Fragment } from "react";

const discordSvg = (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.68088 14.395C7.69535 14.395 6.88402 13.4904 6.88402 12.3803C6.88402 11.2701 7.6799 10.3644 8.68088 10.3644C9.68187 10.3644 10.4943 11.2775 10.4783 12.3803C10.4783 13.491 9.6824 14.395 8.68088 14.395ZM15.3165 14.395C16.302 14.395 17.1133 13.4904 17.1133 12.3803C17.1133 11.2701 16.3174 10.3644 15.3165 10.3644C14.3155 10.3644 13.5031 11.2775 13.5191 12.3803C13.5191 13.491 14.3155 14.395 15.3165 14.395ZM14.2846 5.07186C12.7732 4.84119 11.2246 4.84119 9.71329 5.07186C9.57692 4.74477 9.37555 4.34416 9.19869 4.03039C9.16726 3.97658 9.08469 4.00855 9.03461 4.01867C8.26323 4.17635 5.23471 4.95732 5.03654 5.28548C2.84866 8.55372 2.0011 11.7532 2.00003 14.9155C1.99897 15.4594 2.02454 16.0017 2.07088 16.5435C2.0842 16.6868 2.0565 16.6766 2.16837 16.7582C3.85763 17.9887 5.49415 18.7388 7.1019 19.239C7.12747 19.2465 7.1557 19.238 7.17169 19.2161C7.55578 18.6909 7.89938 18.1374 8.19344 17.5546C8.21049 17.5199 8.19451 17.48 8.15882 17.4667C7.61491 17.26 7.09764 17.0085 6.59901 16.723C6.55959 16.7006 6.55586 16.6442 6.59262 16.617C6.69756 16.5376 6.80198 16.4566 6.90213 16.3741C6.92077 16.3591 6.94528 16.3565 6.96712 16.3655C10.2242 17.8534 13.7753 17.8534 17.0329 16.3655C17.0542 16.356 17.0792 16.3591 17.0973 16.3741C17.1975 16.4566 17.3019 16.5381 17.4074 16.617C17.4441 16.6442 17.4399 16.7006 17.4005 16.723C16.9024 17.0085 16.3846 17.26 15.8407 17.4667C15.8055 17.48 15.789 17.5199 15.806 17.5546C16.1001 18.1374 16.4432 18.6909 16.8278 19.2161C16.8443 19.2374 16.8725 19.2465 16.8981 19.239C18.5059 18.7393 20.1424 17.9887 21.8316 16.7582C21.9435 16.6766 21.9158 16.6868 21.9291 16.5435C21.9755 16.0017 22.001 15.4594 22 14.9155C21.9984 11.7532 21.1508 8.55372 18.9629 5.28548C18.7642 4.95732 15.7362 4.17635 14.9649 4.01867C14.9148 4.00908 14.8317 3.97712 14.8002 4.03039C14.6234 4.34363 14.422 4.74423 14.2856 5.07186H14.2846Z"
      fill="white"
    />
  </svg>
);
const twitterSvg = (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3721 10.9915L12.733 10.0652L8.54939 4H4L10.1878 13.0528L10.8257 13.9859L14.936 19.9997H19.5855L13.3718 10.9912L13.3721 10.9915ZM11.5061 13.186L10.8689 12.2535L5.92015 5.01301H8.01785L12.0532 10.8632L12.6925 11.7903L17.6564 18.9864H15.4711L11.5064 13.1858L11.5061 13.186Z"
      fill="white"
    />
    <path
      d="M10.869 12.2539L11.5061 13.1865L10.826 13.9867L5.71308 20.0004H4.27002L10.1881 13.0533L10.869 12.2539Z"
      fill="white"
    />
    <path
      d="M19.3164 4L13.3721 10.9915L12.6925 11.7906L12.0532 10.8635L12.733 10.0652L17.8999 4H19.3164Z"
      fill="white"
    />
  </svg>
);

/**
 * Renders an SVG icon based on the provided name.
 *
 * @param {Object} props - The props object.
 * @param {string} props.name - values `discord`, `twitter`
 * @return {JSX.Element} The rendered SVG icon.
 */
export const SvgIcon = ({ name = "" }) => {
  const getSvg = () => {
    switch (name) {
      case "discord":
        return discordSvg;
      case "twitter":
        return twitterSvg;

      default:
        return <Fragment />;
    }
  };

  return getSvg();
};
